import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import { Link } from 'gatsby'
import React, { memo } from 'react'
import { ArrowLeft, ArrowRight } from 'react-feather'
import Fade from 'react-reveal/Fade'
import Slider from 'react-slick'

import { Offer, Props as OfferProps } from './Offer'

function PrevArrow(props: any) {
  return (
    <Arrow className="slider-prev" dial={5} onClick={props.onClick}>
      <ArrowLeft />
    </Arrow>
  )
}

function NextArrow(props: any) {
  return (
    <Arrow className="slider-next" dial={5} onClick={props.onClick}>
      <ArrowRight />
    </Arrow>
  )
}

export interface Props {
  offers: OfferProps[]
  title?: string
}

export const OffersPreviewList = memo(function OffersPreviewList({
  offers,
  title,
}: Props) {
  const settings = {
    autoplay: false,
    fade: true,
    infinite: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    speed: 2000,
  }

  if (!offers) {
    return null
  }

  return (
    <Container>
      <Wrapper row wrap stretch>
        {title ? (
          <LeftSide>
            <Fade bottom distance="3.75rem">
              <Title>{title}</Title>
            </Fade>
          </LeftSide>
        ) : null}
        <RightSide>
          <Slider lazyLoad="progressive" {...settings}>
            {offers.map((item, index) => (
              <Offer key={index} {...item} />
            ))}
          </Slider>
        </RightSide>
      </Wrapper>
    </Container>
  )
})

const Container = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 9rem 10vw;

  @media (max-width: 1199px) {
    padding: 9rem 0;
  }

  @media (max-width: 1023px) {
    padding-top: 3.75rem;
    padding-bottom: 7.5rem;
  }
`

const Wrapper = styled(FlexBox)`
  width: 100%;
`

const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  position: relative;

  @media (max-width: 1199px) {
    width: 100%;
    margin: 0 auto 2rem;
    text-align: center;
  }
`

const RightSide = styled.div`
  width: 50%;
  height: 50.222vh;
  display: flex;
  position: relative;

  .slick-slider {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    .slick-list,
    .slick-track {
      height: 100%;
      z-index: 2;

      a {
        display: block;
      }
    }
  }

  @media (max-width: 1199px) {
    width: 100%;
    height: auto;

    .slick-slider {
      width: 50%;
      position: relative;
      margin: auto;
    }
  }
  @media (max-width: 767px) {
    .slick-slider {
      width: calc(100% - 3.75rem);
    }
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 4.375rem;
  font-weight: 400;
  line-height: 5.25rem;

  @media (max-width: 1199px) {
    font-size: 2.5rem;
    line-height: 3rem;
    margin-bottom: 0;
    padding: 0 1.875rem;
    text-align: center;
  }
`

const Arrow = styled(FlexBox)`
  width: 4.5rem;
  height: 4.5rem;
  border: 1px solid ${rgba(theme.colors.variants.neutralLight2, 1)};
  background: ${rgba(theme.colors.variants.neutralLight2, 1)};
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  right: -2.625rem;
  z-index: 5;
  transition: 0.3s ease-in-out;
  &:hover {
    background: ${rgba(theme.colors.variants.neutralLight1, 1)};
    border-color: transparent;
  }
  &.slider-prev {
    top: calc(11.111vh + 4.875rem);
  }
  &.slider-next {
    top: 11.111vh;
  }
  svg {
    width: auto;
    height: 1.75rem;
    stroke: ${({ theme }) => theme.colors.variants.neutralDark1};
    stroke-width: 1;
  }

  @media (max-width: 1199px) {
    z-index: 3;
  }

  @media (max-width: 1023px) {
    &.slider-prev {
      top: auto;
      bottom: -5.25rem;
      left: calc(50% - 4.875rem);
      right: auto;
    }
    &.slider-next {
      top: auto;
      bottom: -5.25rem;
      left: 50%;
      right: auto;
    }
  }
`
