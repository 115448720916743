import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import { Link } from 'gatsby'
import React, { memo } from 'react'

export interface Props {
  buttonLabel?: string
  category?: string
  className?: string
  image?: ImageProps
  languageCode: string
  price?: string
  title: string
  URL: string
}

export const Offer = memo(function Offer({
  buttonLabel = 'details',
  category,
  className,
  image,
  languageCode,
  price,
  title,
  URL,
}: Props) {
  return (
    <Container className={className} data-category={category} to={URL}>
      <Background className="offer-bg">
        {image ? <Image {...image} /> : null}
      </Background>
      <Wrapper>
        <Title>{title}</Title>
        <Info dial={7} row space="between">
          <CTA
            className="offer-cta"
            label={useVocabularyData(buttonLabel, languageCode)}
            noActions
          />
          {price ? (
            <PriceFrom className="offer-price">
              <PriceLabel>
                {useVocabularyData('starting-from', languageCode)}
              </PriceLabel>
              <PriceValue>{price}</PriceValue>
            </PriceFrom>
          ) : null}
        </Info>
      </Wrapper>
    </Container>
  )
})

const Container = styled(Link)`
  height: 50.222vh;
  position: relative;
  &:hover {
    .offer-bg {
      &:before {
        opacity: 0.5;
      }
      img {
        transform: scale(1.2);
      }
    }
    .offer-category {
      opacity: 0;
    }
    .offer-cta {
      padding-right: 3.75rem;
    }
    .offer-price {
      opacity: 1;
      transform: none;
    }
  }

  @media (max-width: 1199px) {
    height: auto;
    &:hover {
      .offer-bg {
        width: 100%;
        height: auto;
        top: auto;
        left: auto;
        img {
          transform: scale(1);
        }
      }
      .offer-category {
        opacity: 1;
      }
      .offer-cta {
        padding-right: 1.875rem;
      }
    }
  }
`

const Background = styled.div`
  width: 100%;
  height: calc(100% - 5.625rem);
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.3s ease-in-out;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    backface-visibility: hidden;
    opacity: 0.3;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    transition: 0.3s ease-in-out;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;
  }

  @media (max-width: 1199px) {
    height: auto;
    padding-bottom: 70%;
    position: relative;
    top: auto;
    left: auto;
  }
  @media (max-width: 767px) {
    padding-bottom: 50%;
  }
`

const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 1.875rem;
  left: 1.875rem;
  z-index: 3;

  @media (max-width: 1199px) {
    position: relative;
    right: auto;
    left: auto;
    z-index: 1;
  }
`

const Title = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.25rem;
  line-height: 1.5rem;
  padding: 2.25rem 5.556vw 2.25rem 2.25rem;

  @media (max-width: 1199px) {
    padding: 1.25rem 2.25rem;
  }
`

const Info = styled(FlexBox)``

const CTA = styled(Button)`
  margin-top: 0;
  transition: 0.3s ease-in-out;
  &:after {
    display: none;
  }

  @media (max-width: 1199px) {
    margin-top: 0;
  }

  @media (max-width: 1199px) {
    width: 100%;
    justify-content: center;
  }
`

const PriceFrom = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  opacity: 0;
  text-align: right;
  transition: 0.3s ease-in-out;
  transform: translateX(-1.5rem);

  @media (max-width: 1199px) {
    display: none;
  }
`

const PriceLabel = styled.div`
  font-size: 0.75rem;
  line-height: 0.875rem;
  text-transform: uppercase;
`

const PriceValue = styled.div`
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 1.625rem;
`
